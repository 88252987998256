<template>
  <article class="single-col-page" :class="{ mobile: isMobile }">
    <div class="l-one__col l-one__col--wide">
      <h1>Marketing Opportunities</h1>

      <h2 class="sr-only">Partnership Overview</h2>
      <p>Looking to work with Electrify America on a marketing related project? You've come to the right place.</p>

      <p>
        Electrify America is seeking partnership proposals that meet its Cycle 3 brand-neutral marketing efforts. Please
        view our public ZEV Cycle 3 Investment Plans
        <router-link class="link" :to="{ name: 'our-plan-en' }" rel="noopener" target="_blank" aria-label="ZEV Cycle 3 Investment Plans here to learn more"
          >here to learn more.
        </router-link>
      </p>
      <div class="headline4">
        <h2 class="sr-only">Current Opportunities</h2>
        At this moment we are exploring the following opportunities:
      </div>
      <ul>
        <li>
          <strong>Event Sponsorships/Event Participation</strong>
          <ul>
            <li>
              Such sponsorships/events must be consumer-oriented to create content/events that promote zero-emission
              vehicle (ZEV) adoption
            </li>
          </ul>
        </li>
        <li>
          <strong>Ride &amp; Drives</strong>
          <ul>
            <li>
              Such programs must be ZEV focused: promoting ZEV awareness, ZEV consideration and/or the overall sales of
              ZEVs
            </li>
          </ul>
        </li>
      </ul>
      <div class="headline4">
        <h2 class="sr-only">Make a marketing opportunity request</h2>
        <h3 class="sr-only">Form guidelines</h3>
        To submit, please follow a few guidelines:
      </div>
      <ol>
        <li>
          Please fill out the form input fields below.&nbsp;
          <ol>
            <li>Must be Zero-Emission Vehicle (ZEV) focused</li>
            <li>Must support ZEV adoption and/or awareness</li>
            <li>Must be within Electrify America’s Cycle 3 timeline (January 2022 - June 2024)</li>
            <li>Preferably be customer focused</li>
          </ol>
        </li>
        <li>If your organization has multiple proposals, please submit a response for each proposal.</li>
        <li>
          All submissions will become the property of Electrify America and should not include proprietary information.
        </li>
      </ol>
      <p>
        Please fill in details below and we will be in touch if we are interested in a further conversation regarding
        your proposal.&nbsp;
      </p>
      <p>
        For non-marketing Cycle 3 submissions, please submit proposals
        <router-link :to="{ name: 'submissions-en' }" rel="noopener" target="_blank" aria-label="submit proposals here">here</router-link>.
      </p>
      <p>
        All submissions are subject to our
        <router-link :to="{ name: 'privacy-en' }" rel="noopener" target="_blank">Privacy Policy</router-link>
        and
        <router-link :to="{ name: 'terms-en' }" rel="noopener" target="_blank">Terms of Use</router-link>
        .
      </p>
      <form id="marketing-form" @submit.prevent="validate" ref="form">
        <h2 class="sr-only">Marketing opportunity form</h2>
        <p aria-hidden="true" class="form-legend">An asterisk (<span>*</span>) indicates a required field</p>
        <label
          >Proposal Option <span class="require-mark" aria-hidden="true">*</span>
          <select
            id="partnership"
            name="partnership"
            required
            title="Partnership Option"
            v-model="answers['PartnershipOption']"
          >
            <option value disabled>--None--</option>
            <option value="Partnership">Partnership</option>
            <option value="Ride &amp; Drive">Ride &amp; Drive</option>
          </select>
        </label>
        <label for="company"
          >Company (Organization) <span class="require-mark" aria-hidden="true">*</span>
          <input id="company" maxlength="100" name="company" required type="text" v-model="answers['Company']" />
        </label>
        <label ref="firstname" for="first_name"
          >First Name <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="first_name"
            maxlength="39"
            name="first_name"
            pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9-])+(?:[a-zA-Z0-9])$"
            required
            type="text"
            v-model="answers['FirstName']"
          />
        </label>
        <label ref="lastname" for="last_name"
          >Last Name <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="last_name"
            maxlength="39"
            name="last_name"
            pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
            title="This field only accepts letters and special characters (no trailing spaces)"
            required
            type="text"
            v-model="answers['LastName']"
          />
        </label>
        <label ref="email" for="email"
          >Email <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="email"
            maxlength="80"
            name="email"
            type="text"
            required
            pattern="[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?:\.[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*@(?:[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_](?:[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_-]*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
            title="Please enter the correct email address"
            v-model="answers['Email']"
          />
        </label>
        <label for="phone"
          >Phone <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="phone"
            maxlength="10"
            name="phone"
            type="text"
            required
            pattern="^\d+$"
            title="This field only accepts numbers"
            v-model="answers['Phone']"
          />
        </label>
        <label for="url"
          >Website <span class="require-mark" aria-hidden="true">*</span>
          <input id="url" maxlength="40" name="url" required type="text" v-model="answers['Website']" />
        </label>
        <div>
          <label for="zev-focus" class="checkbox">
            <input id="zev-focus" name="zev-focus" type="checkbox" value="1" v-model="answers['ZevFocus']" />
            Is this proposal Zero-Emission Vehicle (ZEV) focused?
          </label>
        </div>
        <div>
          <label for="support-zev" class="checkbox">
            <input id="support-zev" name="support-zev" type="checkbox" value="1" v-model="answers['SupportZev']" />
            Does this proposal support ZEV adoption and/or awareness?
          </label>
        </div>
        <div>
          <label for="eacycle" class="checkbox">
            <input id="eacycle" name="eacycle" type="checkbox" value="1" v-model="answers['WithInEACycle']" />
            Does this proposal fall within Electrify America’s Cycle 3 timeline (January 2022 - June 2024)?
          </label>
        </div>
        <div>
          <label for="public-facing" class="checkbox">
            <input
              id="public-facing"
              name="public-facing"
              type="checkbox"
              value="1"
              v-model="answers['IsPublicFacing']"
            />
            Is this proposal public facing?
          </label>
        </div>
        <label for="budget"
          >Budget Estimate <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="budget"
            name="budget"
            maxlength="40"
            type="text"
            required
            pattern="^\d+$"
            title="This field only accepts numbers"
            v-model="budget"
          />
        </label>
        <label for="proposal"
          >Proposal Summary
          <textarea
            id="proposal"
            name="proposal"
            rows="25"
            maxlength="200"
            type="text"
            wrap="soft"
            v-model="answers['ProposalSummary']"
          ></textarea>
        </label>
        <label for="follow-up" class="checkbox">
          <input id="follow-up" name="follow-up" type="checkbox" value="1" v-model="answers['OptMeforFollowup']" />
          I consent that Electrify America may contact me to potentially follow up on the proposal I am submitting:
        </label>
        <div>
          <input ref="submitButton" class="button button--submit" name="submit" type="submit" value="Submit" />
        </div>
        <p v-show="serverError !== ''" class="server-error">
          Error:
          <br />
          {{ serverError }}
        </p>
      </form>
      <h3 class="headline4">Disclaimer</h3>
      <p>
        TERMS OF SUBMISSION: Please do not submit anything in response to this partnership submission opportunity that
        contains protected personal information (such as social security numbers), that may constitute a trade secret,
        or that may be viewed as proprietary and/or confidential, including a patentable invention or a novel business
        idea. Please also review the Terms of Use (“TOU”) for this website prior to submitting any information. The
        Terms of Submission and the TOU shall be applicable to all submissions. Under the terms of the Consent Decree,
        any information you submit to Electrify America LLC (collectively “Electrify America”) through
        ElectrifyAmerica.com may be shared with various governmental entities, and your suggestions about how Electrify
        America should invest in infrastructure, access, and education for zero-emission vehicles may get incorporated
        into Electrify America’s business plans. Electrify America, and any of its current or future parents,
        subsidiaries, corporations, business entities, trusts, commonly owned corporations, or any other entity owning,
        owned by, controlling, controlled by, or under common control or ownership of an entity and their permitted
        successors and assigns (collectively, “Electrify America and its Affiliates”), may use or not use any submitted
        information in any manner that they desire at their sole discretion with no obligation to you. Electrify America
        and its Affiliates have no obligation to keep your submission confidential and may publish, publicly disclose,
        use, or redistribute your submission and/or its contents for any purpose in any way. You should NOT submit any
        information to Electrify America in which you have asserted, or intend in the future to assert, any interest. By
        submitting information to Electrify America in this process, you hereby waive any and all potential rights,
        including intellectual property rights. Your submission and its contents automatically become the property of
        Electrify America, without any compensation owed to you. Any dispute will be resolved by arbitration, with the
        losing party to pay all costs and fees.
      </p>
    </div>
  </article>
</template>

<script>
import ApiService from '@/services/Api';

export default {
  name: 'marketing-opportunities',
  metaInfo: {
    title: 'Marketing your EV event | Electrify America',
    meta: [
      {
        name: 'description',
        content: `If you’re trying to raise awareness of public EV charging, Electrify America wants to hear from you. Fill out our form to submit your marketing project.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/marketing-opportunities/' }],
  },
  data() {
    return {
      budget: '',
      answers: {
        FirstName: '',
        LastName: '',
        Company: '',
        Email: '',
        Phone: '',
        Website: '',
        PartnershipOption: '',
        ZevFocus: false,
        SupportZev: false,
        WithInEACycle: false,
        IsPublicFacing: false,
        BudgetEstimate: undefined,
        ProposalSummary: '',
        OptMeforFollowup: false,
      },
      serverError: '',
    };
  },
  watch: {
    budget(newValue) {
      this.answers.BudgetEstimate = Number(newValue);
    },
    serverError(newValue) {
      this.resetLableColor();
      if (newValue !== '') {
        if (newValue.includes('Email')) {
          this.$refs.email.style.color = '#d90041';
          this.$refs.email.scrollIntoView();
        } else if (newValue.includes('Duplicate')) {
          this.$refs.firstname.style.color = '#d90041';
          this.$refs.lastname.style.color = '#d90041';
          this.$refs.firstname.scrollIntoView();
        }
      }
    },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
  },
  methods: {
    resetLableColor() {
      this.$refs.email.style.color = '#505875';
      this.$refs.firstname.style.color = '#505875';
      this.$refs.lastname.style.color = '#505875';
    },
    async validate(e) {
      e.preventDefault();
      this.serverError = '';

      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: this.answers,
        };
        this.marketingOppsPut(params);
      } catch (error) {
        console.error(error);
      }
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
    marketingOppsPut(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', params.body.Email);
      ApiService.init();
      ApiService.put('/v1/salesforce/marketingOpp', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'email-verification-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = 'There was an error submitting the form, please try again';
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = 'There was an error submitting the form, please try again';

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style scoped lang="scss">
h2,
h3 {
  margin: 20px 0;
}
</style>
